<template>
  <div class="relative">
    <div class="d-none d-lg-flex justify-content-end">
      <div class="register-image" v-if="areaItem && !!areaItem.media">
        <img :src="areaItem.media.general[0].image" v-if="!!areaItem.media.general" alt="">
        <div class="register-bg-video" v-if="videoID">
          <iframe :src="'https://player.vimeo.com/video/'+ videoID +'?background=1&autoplay=1&muted=1&loop=1&byline=0&title=0'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="register-content-wrapper">
      <div class="auth-register-header">
        <div class="container">
          <div class="d-flex justify-content-center align-items-center justify-content-lg-start relative">
            <div class="auth-register-header-logo">
              <router-link to="/">
                <LogoBoxSVG/>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="register-content-holder">
        <div class="container">
          <div class="register-content">
            <button type="button" class="header-arrow d-none d-lg-flex" v-if="showRegisterObjectives || showRegisterPlans || showRegisterPayment" @click="handleBackArrow">
              <chevronLeft/>
            </button>
            <div class="progress-bar-wrapper" v-if="showRegisterComplete || showRegisterObjectives || showRegisterPlans">
              <div class="progress-bar-track">
                <div class="progress-bar" :style="{width:progressBarWidth}"></div>
              </div>
            </div>

            <RegisterComplete v-if="showRegisterComplete" v-bind="{showRegisterComplete:true, showRegisterObjectives:false, showRegisterPlans:false, showRegisterPayment: false}"/>
            <RegisterObjectives v-if="showRegisterObjectives"/>
            <RegisterPlans v-if="showRegisterPlans"/>
            <RegisterPayment v-if="showRegisterPayment"/>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo-black";
import chevronLeft from "@/components/svg/chevron-left.vue";
import RegisterComplete from "@/components/auth/register-complete";
import RegisterObjectives from "@/components/auth/register-objectives";
import RegisterPlans from "@/components/auth/register-plans";
import RegisterPayment from "@/components/auth/register-payment";
import {EventBus} from "@/event-bus";
import axios from "axios";

export default {
  data() {
    return {
      lang: this.$root.siteLang,
      areaSEO: [],
      areaItem: [],
      titleSEO: "",
      descriptionSEO: "",
      keywordsSEO: "",
      showRegisterComplete: true,
      showRegisterObjectives: false,
      showRegisterPlans: false,
      showRegisterPayment: false,
      progressBarWidth: '33.333%',
      videoID: "",
    }
  },
  components: {
    LogoBoxSVG,
    chevronLeft,
    RegisterComplete,
    RegisterObjectives,
    RegisterPlans,
    RegisterPayment,
  },
  methods: {
    handleRegisterComplete: function() {
      this.showRegisterComplete = true;
      this.showRegisterObjectives = false;
      this.showRegisterPlans = false;
      this.showRegisterPayment = false;

      this.progressBarWidth = '50%';
    },
    handleRegisterObjectives: function() {
      this.showRegisterComplete = false;
      this.showRegisterObjectives = true;
      this.showRegisterPlans = false;
      this.showRegisterPayment = false;

      this.progressBarWidth = '66.666%';
    },
    handleRegisterPlans: function() {
      this.showRegisterComplete = false;
      this.showRegisterObjectives = false;
      this.showRegisterPlans = true;
      this.showRegisterPayment = false;

      this.progressBarWidth = '100%';
    },
    handleRegisterPayment: function() {
      this.showRegisterComplete = false;
      this.showRegisterObjectives = false;
      this.showRegisterPlans = false;
      this.showRegisterPayment = true;

      this.progressBarWidth = '100%';
    },
    handleBackArrow: function() {
      if ( this.showRegisterObjectives == true ) {
        this.handleRegisterComplete();
      } else if ( this.showRegisterPlans == true ) {
        //this.handleRegisterObjectives();
        this.handleRegisterComplete();
      } else if ( this.showRegisterPayment == true ) {
        this.handleRegisterPlans();
      }
    },
  },
  mounted() {
    EventBus.$on('bus-handleRegisterComplete', () => {
      this.showRegisterComplete = true;
      this.showRegisterObjectives = false;
      this.showRegisterPlans = false;
      this.showRegisterPayment = false;

      this.progressBarWidth = '50%';
    });

    EventBus.$on('bus-handleRegisterObjectives', () => {
      this.showRegisterComplete = false;
      this.showRegisterObjectives = true;
      this.showRegisterPlans = false;
      this.showRegisterPayment = false;

      this.progressBarWidth = '66.666%';
    });

    EventBus.$on('bus-handleRegisterPlans', () => {
      this.showRegisterComplete = false;
      this.showRegisterObjectives = false;
      this.showRegisterPlans = true;
      this.showRegisterPayment = false;

      this.progressBarWidth = '100%';
    });

    EventBus.$on('bus-handleRegisterPayment', () => {
      this.showRegisterComplete = false;
      this.showRegisterObjectives = false;
      this.showRegisterPlans = false;
      this.showRegisterPayment = true;

      this.progressBarWidth = '100%';
    });
  },
  created() {
    //Background
    axios
        .get(process.env.VUE_APP_API_URL + 'areas/cp_29?with_category_title=0&with_seo=0&lang=' + this.lang)
        .then((response) => {
          this.areaItem = response.data;
          this.videoID = this.areaItem.media.videos[0].url.split('/').pop();
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
};
</script>