<template>
  <div class="register-step register-objectives">
    <div class="auth-form-heading">
      <p class="auth-form-title">QUAL O seu OBJETIVO?</p>
      <p class="auth-form-subtitle">Vamos ajuda-te a treinar melhor e cumprires os teus objetivos</p>
    </div>
    <div class="auth-form-wrapper">
      <form action="" class="auth-form objectives-form">
        <div class="row box-form-row">
          <div class="col-12 col-md-6 box-form-column">
            <div class="select-box-wrapper">
              <label>
                <input type="checkbox" name="objectives" value="getInShape">
                <div class="select-box">
                    <div class="select-box-image">
                      <img src="@/assets/img/objective-1.jpg" srcset="@/assets/img/objective-1.jpg 1x, @/assets/img/objective-1@2x.jpg 2x" alt="">
                    </div>
                    <div class="select-box-content-wrapper d-flex align-items-end justify-content-between">
                      <div class="select-box-content">
                        <bodyIcon/>
                        <p class="select-box-title">ENTRAR EM FORMA</p>
                        <p class="select-box-subtitle">Melhorar a minha condição fisica</p>
                      </div>
                      <div class="select-box-checkbox"></div>
                    </div>
                </div>
              </label>
            </div>
          </div>
          <div class="col-12 col-md-6 box-form-column">
            <div class="select-box-wrapper">
              <label>
                <input type="checkbox" name="objectives" value="loseWeight">
                <div class="select-box">
                  <div class="select-box-image">
                    <img src="@/assets/img/objective-2.jpg" srcset="@/assets/img/objective-2.jpg 1x, @/assets/img/objective-2@2x.jpg 2x" alt="">
                  </div>
                  <div class="select-box-content-wrapper d-flex align-items-end justify-content-between">
                    <div class="select-box-content">
                      <bodyIcon/>
                      <p class="select-box-title">EMAGRECER</p>
                      <p class="select-box-subtitle">Perde de peso </p>
                    </div>
                    <div class="select-box-checkbox"></div>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="col-12 col-md-6 box-form-column">
            <div class="select-box-wrapper">
              <label>
                <input type="checkbox" name="objectives" value="beHealthy">
                <div class="select-box">
                  <div class="select-box-image">
                    <img src="@/assets/img/objective-3.jpg" srcset="@/assets/img/objective-3.jpg 1x, @/assets/img/objective-3@2x.jpg 2x" alt="">
                  </div>
                  <div class="select-box-content-wrapper d-flex align-items-end justify-content-between">
                    <div class="select-box-content">
                      <bodyIcon/>
                      <p class="select-box-title">ESTAR SAÙDAVEL</p>
                      <p class="select-box-subtitle">Quero me sentir melhor</p>
                    </div>
                    <div class="select-box-checkbox"></div>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="col-12 col-md-6 box-form-column">
            <div class="select-box-wrapper">
              <label>
                <input type="checkbox" name="objectives" value="moreMuscle">
                <div class="select-box">
                  <div class="select-box-image">
                    <img src="@/assets/img/objective-4.jpg" srcset="@/assets/img/objective-4.jpg 1x, @/assets/img/objective-4@2x.jpg 2x" alt="">
                  </div>
                  <div class="select-box-content-wrapper d-flex align-items-end justify-content-between">
                    <div class="select-box-content">
                      <bodyIcon/>
                      <p class="select-box-title">MAIS MÚSCULOS</p>
                      <p class="select-box-subtitle">Ganhar massa muscular</p>
                    </div>
                    <div class="select-box-checkbox"></div>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="col-12 col-md-6 box-form-column">
            <div class="select-box-wrapper">
              <label>
                <input type="checkbox" name="objectives" value="noObjective">
                <div class="select-box">
                  <div class="select-box-image">
                    <img src="@/assets/img/objective-5.jpg" srcset="@/assets/img/objective-5.jpg 1x, @/assets/img/objective-5@2x.jpg 2x" alt="">
                  </div>
                  <div class="select-box-content-wrapper d-flex align-items-end justify-content-between">
                    <div class="select-box-content">
                      <bodyIcon/>
                      <p class="select-box-title">SEM OBJETIVOS</p>
                      <p class="select-box-subtitle">Não tenho nenhum objetivo definido</p>
                    </div>
                    <div class="select-box-checkbox"></div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted">
          <p>{{ formResponseMessage }}</p>
        </div>
        <button type="button" @click="handleRegisterPlans" class="generic-btn red-gradient auth-form-submit">Seguinte</button>
      </form>
    </div>
  </div>
</template>

<script>
import bodyIcon from "@/components/svg/body-icon.vue";
import {EventBus} from "@/event-bus";

export default {
  name: "auth",
  data() {
    return {
      values: {},
      errors: [],
      submitted: false,
      showMessage: false,
      formResponseMessage: '',
    };
  },
  components : {
    bodyIcon,
  },
  methods: {
    handleRegisterPlans: function() {
      var objectives = document.getElementsByName("objectives");
      let checkedObjectives = [];

      for( var i = 0; i < objectives.length; i++ ) {
        if( objectives[i].checked ) {
          checkedObjectives.push(objectives[i].value);
        }
      }

      if ( checkedObjectives.length > 0 ) {
        this.$root.registerFormInfo.objectives = checkedObjectives;

        EventBus.$emit("bus-handleRegisterPlans");
      } else {
        this.showMessage = true;
        this.formResponseMessage = 'You have to select a objective';
      }

    }
  }
}
</script>