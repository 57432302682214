<template>
  <div class="register-step register-plans">
    <div class="auth-form-heading">
      <p class="auth-form-title">ESCOLHE O TEU PLANO</p>
      <p class="auth-form-subtitle">De acordo com os teus objetivos disponibilizamos os seguinte planos</p>
    </div>
    <div class="auth-form-wrapper">
      <form action="" class="auth-form">
        <div class="row box-form-row">
          <div class="col-12 col-md-6 box-form-column">
            <div class="plan-item-wrapper">
              <label>
                <input type="radio" name="payment-method" value="free" btnText="Gratuíto" checked>
                <div class="plan-item d-flex flex-column justify-content-start align-items-center">
                  <div class="plan-item-icon">
                    <lockOpen/>
                  </div>
                  <p class="plan-item-title">GRATUÍTO</p>
                  <p class="plan-item-subtitle">Acesso a uma seleção de treinos e execícios disponiveis na plataforma</p>
                  <p class="plan-item-payment"></p>
                </div>
              </label>
            </div>
          </div>
          <div class="col-12 col-md-6 box-form-column">
            <div class="plan-item-wrapper">
              <label>
                <input type="radio" name="payment-method" value="premium" btnText="Premium">
                <div class="plan-item d-flex flex-column justify-content-center align-items-center">
                  <div class="plan-item-icon">
                    <lock/>
                  </div>
                  <p class="plan-item-title">PREMIUM</p>
                  <p class="plan-item-subtitle">Acesso a todos os treinos e execícios disponiveis na plataforma</p>
                  <div class="plan-item-price">
                    <span class="smaller">desde</span>
                    5,99€
                    <span>/ Mês</span>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted" v-html="formResponseMessage"></div>
        <button type="button" @click="handleRegisterPayment" class="generic-btn red-gradient auth-form-submit">Começar {{ selectedPlanText }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import lock from "@/components/svg/lock2.vue";
import lockOpen from "@/components/svg/lock2-open.vue";
import {EventBus} from "@/event-bus";
import axios from "axios";
import qs from "qs";

export default {
  name: "auth",
  data() {
    return {
      errors: [],
      selectedPlanText: 'Gratuíto',
      showMessage: false,
      submitted: false,
      formResponseMessage: '',
    };
  },
  components : {
    lock,
    lockOpen
  },
  methods: {
    resetRegisterHandler() {
      this.$root.registerFormInfo = [];
    },
    handleRegisterPayment: function() {
      let selectedPlan = document.querySelector('input[name="payment-method"]:checked').value;
      this.$root.registerFormInfo.plan = selectedPlan;

      if ( selectedPlan == 'premium' ) {
        EventBus.$emit("bus-handleRegisterPayment");
      } else {
        axios
           .post(process.env.VUE_APP_API_URL + 'customer/register', qs.stringify(this.$root.registerFormInfo))
           .then((response) => {
             if (response.data.success) {
               this.resetRegisterHandler();
               this.$root.loggedIn = true;
               this.$root.authKey = response.data.model.auth_key;

               this.$cookies.set("loggedIn",true,60 * 60 * 4);
               this.$cookies.set("authKey",response.data.model.auth_key,60 * 60 * 4);

               this.$root.userInfo = response.data.model;
               this.$router.push({ name: 'dashboard' });
             }
           })
           .catch((e) => {
              if ( e.response.status >= 500 ) {
                this.errors.push(e);
                this.showMessage = true;
                this.formResponseMessage = 'An error occurred.';
              } else {
                let errorMessage = '';

                let errors = e.response.data.errors;
                errors = Object.values(errors);

                for( var i = 0; i < errors.length; ++i ) {
                  errorMessage += '<p>' + errors[i].toString() + '</p>';
                }

                this.showMessage = true;
                this.formResponseMessage = errorMessage;
              }
           });
      }
    },
  },
  mounted() {
    let inputs = Array.from(document.querySelectorAll('.plan-item-wrapper input'));

    let componentData = this;

    inputs.forEach(node => {
      node.onchange = function() {
        if ( node.checked ) {
          componentData.selectedPlanText = node.getAttribute('btnText');
        }
      }
    });
  }
}
</script>